<template>
  <v-content sticky-container>
    <v-container v-if="_.size(order) > 0" grid-list-lg fluid>
      <v-layout wrap align-top justify-left>
        <OrderNav :order="this.order" :get-order="getOrder" :go-route="goRoute" :dialog-status="dialogStatus">
        </OrderNav>
        <v-flex xs12 lg9 sm8 md8>
          <template v-if="user.shopID !== order.shop_id">
            <v-alert :key="index" v-model="alert" type="warning" icon="new_releases" class="ml-0 mb-3">
              Este pedido no es de tu area de cobertura
            </v-alert>
          </template>
          <template v-if="order.importants.length > 0">
            <v-alert
              v-for="(important, index) in order.importants"
              :key="index"
              v-model="alert"
              type="error"
              icon="new_releases"
              class="ml-0 mb-3"
            >
              <span v-html="important.message"></span>
            </v-alert>
          </template>
          <template v-if="showDesigner && order.preparing.length > 0">
            <v-card color="warning" class="mb-2">
              <titleCard
                title="Diseñadores"
                subtitle="Los siguientes diseñadores estan trabajando en este pedido:"
              ></titleCard>
              <v-divider light></v-divider>
              <v-data-table
                color="primary"
                :items="order.preparing"
                :headers="[
                  { text: 'Disenador', align: 'left', sortable: false },
                  { text: 'Producto', align: 'left', sortable: false },
                  { text: 'Tiempo', align: 'left', sortable: false }
                ]"
                class="elevation-0 primary"
                hide-actions
              >
                <template slot="items" slot-scope="props">
                  <td>{{ props.item.name }}</td>
                  <td>{{ props.item.product }}</td>
                  <td>
                    <v-chip v-if="props.item.status === 0" color="warning" text-color="white">
                      <v-avatar>
                        <v-icon>timelapse</v-icon>
                      </v-avatar>
                      <TimeGo :number="props.item.seconds"></TimeGo>
                    </v-chip>
                    <v-chip v-if="props.item.status === 1" color="success" text-color="white">
                      <v-avatar>
                        <v-icon>check_circle</v-icon>
                      </v-avatar>
                      Confeccionado
                    </v-chip>
                  </td>
                </template>
              </v-data-table>
            </v-card>
          </template>
          <template v-if="order.state.id === 3 || order.state.id === 4">
            <v-alert
              :key="index"
              v-model="alert"
              :type="order.dealer.delivered ? 'success' : 'info'"
              :icon="order.dealer.delivered ? 'check_circle_outline' : 'directions_car'"
              class="ml-0 mb-3"
            >
              <p v-if="order.dealer.delivered" v-html="order.dealer.info.message"></p>
              <p v-else>
                Pedido va en camino {{ $moment.unix(order.dealer.info.date).fromNow() }}, el repartidor es
                <span class="font-weight-bold" v-html="order.dealer.name"></span>
                <v-btn
                  color="error"
                  small
                  absolute
                  right
                  style="bottom:16px;right:210px;"
                  @click.stop="removeOrderRouteAction(order.id)"
                >
                  Quitar
                </v-btn>
                <v-btn
                  :to="{ name: 'ordersMapDealer', params: { dealerID: order.dealer.id } }"
                  color="secondary"
                  small
                  absolute
                  right
                  style="bottom:16px;right:115px;"
                >
                  Ver ruta
                </v-btn>
                <v-btn
                  :href="order.links.donde_esta"
                  target="_blank"
                  color="success"
                  small
                  absolute
                  right
                  style="bottom:16px;right:20px;"
                >
                  En vivo
                </v-btn>
              </p>
            </v-alert>
          </template>
          <router-view :open-change-status="openChangeStatus" :order="order" :get-order="getOrder" />
        </v-flex>
      </v-layout>
    </v-container>
  </v-content>
</template>

<script>
import { GET_API, ORDER } from '../../config'
import titleCard from '../useful/titleCard.vue'
import OrderNav from './orderNav.vue'
import TimeGo from '../useful/timeGo.vue'

export default {
  name: 'Order',
  components: {
    OrderNav,
    titleCard,
    TimeGo
  },
  props: ['id'],
  data() {
    return {
      showDesigner: false,
      dialogStatus: false,
      visibility: true,
      carruselOption: {
        slidesToShow: 1,
        infinite: true,
        centerMode: false,
        dots: true,
        adaptiveHeight: true,
        autoplay: true,
        autoplaySpeed: 2000,
        swipeToSlide: true,
        arrows: false
      },
      loading: false,
      alert: true,
      alertMore: false,
      order: {},
      status: {
        id: null,
        message: null,
        comments: null,
        notify: 0,
        important: 0,
        whatsapp: 0
      },
      dialog: {
        schedule: false,
        status: false
      },
      schedule: {
        date: null,
        name: null
      },
      photos: [],
      config: {
        font_names: this.fontReturn('font'),
        contentsCss: this.fontReturn('css'),
        toolbar: [
          {
            name: 'basicstyles',
            groups: ['basicstyles', 'cleanup'],
            items: ['Bold', 'Italic', 'Underline', '-', 'JustifyCenter']
          },
          {
            name: 'styles',
            items: ['Font', 'FontSize']
          }
        ],
        language: 'es',
        height: 150
      },
      title: ''
    }
  },
  computed: {
    height() {
      return `${window.innerHeight - 80}px`
    },
    user() {
      return this.$store.getters.getUser
    },
    payments() {
      return this.$store.getters.getPayments
    },
    comments() {
      return this.$store.getters.getComments
    },
    states() {
      return this.$store.getters.getStates
    }
  },
  watch: {
    alert(e) {
      if (e === false) {
        this.alertMore = true
      }
    },
    id() {
      this.getOrder()
    }
  },
  mounted() {
    this.getOrder()
  },
  methods: {
    removeOrderRouteAction(item) {
      this.$store.dispatch('setConfirm', {
        active: true,
        title: '¿Quitar de la ruta?',
        description: '',
        action: () => this.removeOrderRoute(item)
      })
    },
    removeOrderRoute(item) {
      this.$http({
        method: 'post',
        url: GET_API,
        params: {
          p: 'removeRouteOrdersMap',
          orderID: item
        }
      }).then(() => {
        this.$store.dispatch('setConfirm', {
          active: false,
          title: '',
          description: '',
          action: {}
        })
        this.getOrder()
      })
    },
    goRoute(item) {
      if (item.route === 'copy') {
        let link = ''
        switch (item.text) {
          case 'Encuesta':
            link = this.order.links.encuesta
            break
          case 'Geolocalizacion':
            link = this.order.links.geolocalizar
            break
          case 'Vista en vivo':
            link = this.order.links.donde_esta
            break
          case 'Cambiar mensaje':
            link = this.order.links.cambio_mensaje
            break
          case 'Estado del pedido':
            link = this.order.links.estado
            break
          case 'Foto de entega':
            link = this.order.links.photo
            break
          default:
            link = ''
            break
        }
        this.$copyText(link).then(e => {
          this.$store.dispatch('changeSnack', { active: true, text: 'Copiado con exito!' })
        })
      } else {
        this.$router.push({ name: item.route, params: { id: this.id } })
        const el = this.$el.getElementsByClassName('ubicacion')[0]
        el.scrollIntoView()
      }
    },
    updateform() {
      this.schedule.date = this.order.shipping.date
      this.schedule.name = this.order.shipping.schedule
      this.status.id = this.order.state.id
      if (this.order.importants.length > 0) {
        this.alertMore = true
      }
      const count = this._.filter(this.order.preparing, ['status', 0])
      if (count.length > 0) {
        this.showDesigner = true
      }
    },
    async getOrder() {
      const res = await this.$http.get(`${ORDER}/${this.id}`)
      if (res.data) {
        this.order = res.data
        this.$store.dispatch('updateTitle', `Pedido ${this.order.id}`)
        this.title = `Pedido ${this.order.id}`
        this.updateform()
      }
    },
    fontReturn(type) {
      const myFonts = [
        'Dancing Script',
        'Nothing You Could Do',
        'Great Vibes',
        'Marck Script',
        'Homemade Apple',
        'Reenie Beanie',
        'Just Me Again Down Here',
        'Caveat',
        'Dawning of a New Day',
        'Cedarville Cursive'
      ]
      let fontNames = 'Georgia;Times New Roman/Times New Roman, Times, serif'
      for (let i = 0; i < myFonts.length; i++) {
        fontNames = `${fontNames};${myFonts[i]}`
        myFonts[i] = `https://fonts.googleapis.com/css?family=${myFonts[i].replace(' ', '+')}`
      }
      if (type === 'css') {
        return ['/ckeditor/contents.css'].concat(myFonts)
      }
      return fontNames
    },
    openChangeStatus() {
      this.dialogStatus = !this.dialogStatus
    }
  },
  metaInfo() {
    return {
      title: this.title
    }
  }
}
</script>

<style>
.slick-dots {
  list-style: none;
  padding: 0;
  margin: 0;
  flex: 1;
  display: flex;
  justify-content: center;
  position: absolute;
  width: 100%;
  bottom: 0;
}
.slick-dots button {
  font-size: 0;
  background: rgba(0, 0, 0, 0.12);
  width: 20px;
  height: 20px;
  border-radius: 20px;
  margin: 0 4px;
  outline: none;
}

.slick-dots .slick-active button {
  background: #ffa726;
}

.v-alert p {
  margin-bottom: 0;
}
</style>
