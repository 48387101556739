<template>
  <v-flex xs12 sm4 md4 lg3 xl3>
    <v-card
      v-if="_.size(order) > 0"
      v-sticky="shouldStick"
      sticky-offset="offset"
      sticky-side="both"
      sticky-z-index="Zindex"
      class="mx-auto elevation-1"
      width="100%"
    >
      <v-card-text
        v-if="order.state.id !== 4"
        style="position: relative; margin: 0 auto; padding-bottom: 0px"
        @mouseover="btnHiddenPhoto = true"
        @mouseleave="btnHiddenPhoto = false"
      >
        <Slick ref="slick" :options="carruselOption">
          <div v-for="(item, i) in order.products" :key="i" class="text-center">
            <v-img :src="item.photo" aspect-ratio="1" contain @click="openImage(item.photo)"></v-img>
          </div>
        </Slick>
      </v-card-text>
      <div v-else class="img-entrega-download">
        <v-btn
          color="accent"
          small
          bottom
          left
          absolute
          style="bottom: 10px; left: 5px;"
          :to="{
            name: 'orderProducts',
            params: { id: order.id },
            query: { 'foto-entrega': 1 }
          }"
        >
          Verificar publicacion
        </v-btn>
        <v-btn color="info" small fab top left absolute style="top: 10px" @click="downloadItem(order)">
          <v-icon>cloud_download</v-icon>
        </v-btn>
        <img :src="order.photo_medium" style="max-width: 100%" @click="openImage(order.photo)" />
      </div>

      <v-card-title style="position: relative">
        <div v-if="order.shipping !== undefined">
          <span class="link-text headline font-weight-bold primary--text mb-2" @click="dialog.status = true">
            {{ this.order.state.name }}
          </span>
          <br />
          <span
            class="link-text font-weight-regular grey--text text--darken-1 subheading"
            style="text-transform: capitalize"
            @click="dialog.schedule = true"
          >
            {{ order.shipping.date | moment('dddd, DD MMMM YYYY') }}
          </span>
          <br />
          <span
            class="link-text font-weight-regular grey--text text--darken-1 subheading"
            @click="dialog.schedule = true"
            v-html="order.shipping.schedule"
          >
          </span>
          <span
            v-if="order.priority.name === 'alta'"
            class="link-text font-weight-regular primary--text text--darken-1 subheading"
            @click="dialog.schedule = true"
          >
            Finaliza a las {{ order.priority.hour }} hrs
          </span>
          <div v-if="order.state.id === 4">
            <v-chip class="ml-0" color="info" dark>
              <v-avatar>
                <v-icon>account_circle</v-icon>
              </v-avatar>
              Entregado por
              <span class="font-weight-bold ml-1" v-html="order.dealer.name"></span>
            </v-chip>
          </div>
          <v-tooltip top>
            <v-btn
              v-if="order.priority.name === 'baja'"
              slot="activator"
              color="warning"
              fab
              top
              right
              absolute
              target="_blank"
              @click="changeOrderPriority(1)"
            >
              <v-icon>arrow_upward</v-icon>
            </v-btn>
            <v-btn
              v-else-if="order.priority.name === 'media'"
              slot="activator"
              color="error"
              fab
              top
              right
              absolute
              target="_blank"
              @click="changeOrderPriority(0)"
            >
              <v-icon>arrow_downward</v-icon>
            </v-btn>
            <span v-if="order.priority.name === 'baja'">Aumentar Prioridad</span>
            <span v-else-if="order.priority.name === 'media'">Bajar Prioridad</span>
          </v-tooltip>
        </div>
      </v-card-title>
      <v-divider light></v-divider>
      <v-card-actions>
        <v-tooltip top>
          <v-btn
            slot="activator"
            :color="order.status.document.active ? 'success' : 'error'"
            flat
            style="margin-left: 4px"
            icon
            target="_blank"
            :href="order.status.document.active ? order.sii.url_doc : '#'"
          >
            <v-icon>file_copy</v-icon>
          </v-btn>
          <span> SII </span>
        </v-tooltip>
        <v-tooltip top>
          <v-btn
            slot="activator"
            :color="order.status.print.active ? 'success' : 'error'"
            flat
            style="margin-left: 4px"
            icon
            :to="{ name: 'orderPrint', params: { id: order.id } }"
          >
            <v-icon>print</v-icon>
          </v-btn>
          <span> Imprimir </span>
        </v-tooltip>
        <v-tooltip top>
          <v-btn
            slot="activator"
            :color="order.status.design.active ? 'success' : 'error'"
            flat
            style="margin-left: 4px"
            icon
            target="_blank"
            @click="goRoute({ route: 'orderDesign' })"
          >
            <v-icon>local_florist</v-icon>
          </v-btn>
          <span> Diseñador </span>
        </v-tooltip>
        <v-tooltip top>
          <v-btn
            slot="activator"
            :color="order.status.location.active ? 'success' : 'error'"
            flat
            style="margin-left: 4px"
            icon
            target="_blank"
            @click="goRoute({ route: 'orderLocation' })"
          >
            <v-icon>location_on</v-icon>
          </v-btn>
          <span> Geolocalización </span>
        </v-tooltip>
        <v-spacer></v-spacer>
        <v-menu
          transition="slide-y-transition"
          :nudge-width="400"
          offset-x
          :close-on-content-click="false"
          bottom
          center
          small
        >
          <v-btn slot="activator" icon>
            <v-icon>link</v-icon>
          </v-btn>
          <v-card>
            <v-card-text>
              <v-text-field
                v-model="order.links.encuesta"
                label="Encuesta"
                color="secondary"
                readonly
                prepend-icon="launch"
                append-icon="content_cut"
                @click.native="$event.target.select()"
                @click="copyLink(order.links.encuesta)"
                @click:append="copyLink(order.links.encuesta)"
                @click:prepend="goLink(order.links.encuesta)"
              ></v-text-field>
              <v-text-field
                v-model="order.links.geolocalizar"
                label="Geolocalizacion"
                color="secondary"
                readonly
                prepend-icon="launch"
                append-icon="content_cut"
                @click.native="$event.target.select()"
                @click="copyLink(order.links.geolocalizar)"
                @click:append="copyLink(order.links.geolocalizar)"
                @click:prepend="goLink(order.links.geolocalizar)"
              ></v-text-field>
              <v-text-field
                v-model="order.links.estado"
                label="Estado del pedido"
                color="secondary"
                readonly
                prepend-icon="launch"
                append-icon="content_cut"
                @click.native="$event.target.select()"
                @click="copyLink(order.links.estado)"
                @click:append="copyLink(order.links.estado)"
                @click:prepend="goLink(order.links.estado)"
              ></v-text-field>
              <v-text-field
                v-model="order.links.estado"
                label="Foto de entega"
                color="secondary"
                readonly
                prepend-icon="launch"
                append-icon="content_cut"
                @click.native="$event.target.select()"
                @click="copyLink(order.links.estado)"
                @click:append="copyLink(order.links.estado)"
                @click:prepend="goLink(order.links.estado)"
              ></v-text-field>
              <v-text-field
                v-if="order.sii.facturacion === 2"
                v-model="order.links.datos_facturacion"
                label="Datos de facturacion"
                color="secondary"
                readonly
                prepend-icon="launch"
                append-icon="content_cut"
                @click.native="$event.target.select()"
                @click="copyLink(order.links.datos_facturacion)"
                @click:append="copyLink(order.links.datos_facturacion)"
                @click:prepend="goLink(order.links.datos_facturacion)"
              ></v-text-field>
            </v-card-text>
          </v-card>
        </v-menu>
        <v-menu transition="slide-y-transition" top right small>
          <v-btn slot="activator" icon>
            <v-icon>more_vert</v-icon>
          </v-btn>
          <v-list dense class="pa-0">
            <v-list-tile v-for="(action, i) in actions" :key="i" @click="handleClickMenu(action.route)">
              <v-list-tile-title class="text-xs-right" v-text="action.text"></v-list-tile-title>
            </v-list-tile>
          </v-list>
        </v-menu>
      </v-card-actions>
      <v-divider light></v-divider>
      <v-list class="ma-0 py-0" :dense="$mq !== 'lg'">
        <template v-for="(item, i) in items">
          <v-layout v-if="item.heading" :key="i" row align-center>
            <v-flex xs12>
              <v-subheader v-if="item.heading">
                {{ item.heading }}
              </v-subheader>
            </v-flex>
          </v-layout>
          <v-divider v-else-if="item.divider" :key="i" dark class="my-3"></v-divider>
          <v-list-tile v-else :key="i" :class="$route.name === item.route ? 'active' : ''" @click="goRoute(item)">
            <v-list-tile-action class="mr-0 pl-0">
              <v-icon class="ml-2">{{ item.icon }}</v-icon>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title class="grey--text">
                {{ item.text }}
              </v-list-tile-title>
            </v-list-tile-content>
            <v-list-tile-action v-if="item.route === 'orderProducts'">
              <v-chip color="primary" class="ma-0 white--text" label small>
                {{ order.total | toPrice }}
              </v-chip>
            </v-list-tile-action>
            <v-list-tile-action v-if="item.route === 'orderHome'">
              <v-tooltip v-if="order.origin === 'RED'" right>
                <v-icon slot="activator" color="accent"> desktop_windows </v-icon>
                <span> Red </span>
              </v-tooltip>
              <v-tooltip v-else-if="order.origin === 'WEB' || order.origin === 'PC'" right>
                <v-icon slot="activator" color="accent">web</v-icon>
                <span> PC </span>
              </v-tooltip>
              <v-tooltip v-else-if="order.origin === 'MOBILE'" right>
                <v-icon slot="activator" color="accent"> smartphone </v-icon>
                <span> Mobile / Tablet </span>
              </v-tooltip>
            </v-list-tile-action>
          </v-list-tile>
          <v-divider v-if="i + 1 !== items.length" :key="`div-${i}`" inset></v-divider>
        </template>
      </v-list>
    </v-card>
    <v-dialog v-model="dialog.photo" max-width="500px" style="overflow: hidden">
      <img :src="photoLarge" style="max-width: 100%; margin-bottom: -8px" />
    </v-dialog>
    <v-dialog v-model="dialog.status" max-width="500px" persistent>
      <v-card>
        <TitleDialog :title="'Actualizar Estado'" close :close-action="closeDialogStatus"></TitleDialog>
        <v-card-text class="pa-3">
          <v-layout wrap>
            <v-flex xs12>
              <v-select
                v-model="status.id"
                hide-details
                :items="states"
                item-text="name"
                item-value="id"
                label="Estado"
              ></v-select>
            </v-flex>
            <v-flex xs12>
              <v-select
                v-model="status.comments"
                :items="comments"
                item-text="name"
                :item-value="
                  item => {
                    return item
                  }
                "
                label="Comentarios"
              ></v-select>
            </v-flex>
            <v-flex xs12>
              <QuillEditorFormat
                v-model="status.message"
                :reference="'changeStatus'"
                placeholder="Comentario"
              ></QuillEditorFormat>
            </v-flex>
            <v-flex xs12 class="pb-0">
              <v-subheader> Notificar </v-subheader>
            </v-flex>
            <v-flex xs4>
              <v-switch v-model="status.notify" :label="`Email`" class="mt-0" hide-details> </v-switch>
            </v-flex>
            <v-flex xs4>
              <v-switch v-model="status.whatsapp" class="mt-0" :label="`Whatsapp`" hide-details> </v-switch>
            </v-flex>
            <v-flex xs1>
              <v-divider vertical></v-divider>
            </v-flex>
            <v-flex xs3>
              <v-switch v-model="status.important" class="mt-0" :label="`Destacar`" hide-details> </v-switch>
            </v-flex>
            <v-flex xs12 class="pa-0">
              <v-btn :class="status.photo ? 'mt-2' : 'mt-4'" color="accent" depressed block @click="$refs.file.click()">
                Subir imagen/documento <v-icon right dark>cloud_upload</v-icon>
              </v-btn>
              <span v-if="this.status.file" class="caption" v-html="`Subir ${this.status.file.name}`"></span>
              <input
                id="file"
                ref="file"
                type="file"
                style="display: none"
                accept=".doc, .docx, .pdf, .jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*"
                @change="handleFileUpload()"
              />
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="back" flat @click.native="dialog.status = false"> Volver </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="secondary" :loading="loading" :disabled="loading" flat @click.stop="updateStatus">
            Actualizar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog.schedule" max-width="500px">
      <v-card>
        <v-toolbar class="elevation-0" color="secondary" dark>
          <v-toolbar-title class="font-weight-regular">Modificar horario</v-toolbar-title>
        </v-toolbar>
        <v-container class="pa-3" grid-list-lg>
          <v-layout>
            <v-flex xs6>
              <v-menu
                ref="menuScheduleDateRef"
                v-model="menuScheduleDate"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="schedule.date"
                lazy
                transition="scale-transition"
                offset-y
                full-width
                max-width="290px"
                min-width="290px"
              >
                <v-text-field
                  slot="activator"
                  v-model="schedule.date"
                  label="Fecha de entrega"
                  prepend-icon="event"
                  readonly
                ></v-text-field>
                <v-date-picker v-model="schedule.date" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn flat color="primary" @click="menuScheduleDate = false">Volver</v-btn>
                  <v-btn flat color="primary" @click="$refs.menuScheduleDateRef.save(schedule.date)">Confirmar</v-btn>
                </v-date-picker>
              </v-menu>
            </v-flex>
            <v-flex xs6>
              <v-text-field v-model="schedule.name" label="Horario de entrega" placeholder="Placeholder"></v-text-field>
            </v-flex>
          </v-layout>
        </v-container>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="greyback" flat @click.native="dialog.schedule = false"> Volver </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="secondary" flat :loading="loading" :disabled="loading" @click.native="updateSchedule">
            Actualizar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog.orderEmail" max-width="600px" persistent>
      <v-card>
        <TitleDialog
          :title="'Pedido completo'"
          close
          :close-action="
            () => {
              dialog.orderEmail = false
            }
          "
        ></TitleDialog>
        <v-container class="pa-3" grid-list-lg>
          <v-layout row wrap>
            <v-flex xs12>
              <QuillEditorFormat
                v-model="orderMail"
                :reference="'orderComplete'"
                placeholder="Pedido completo"
              ></QuillEditorFormat>
            </v-flex>
            <v-flex xs12>
              <v-text-field
                v-model="orderEmailConfirmation"
                label="Email donde se enviara..."
                hint="Si es otro email, ingreselo"
                :placeholder="order.user.email"
                persistent-hint
              >
              </v-text-field>
            </v-flex>
          </v-layout>
        </v-container>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="greyback" flat @click.native="dialog.orderEmail = false"> Volver </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="secondary"
            flat
            :loading="loadingDialog"
            :disabled="loadingDialog"
            @click.native="orderEmailSend"
          >
            Enviar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog.orderPoll" max-width="200px">
      <v-card>
        <v-toolbar class="elevation-0" color="secondary" dark>
          <v-toolbar-title class="font-weight-regular">Encuesta</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-radio-group v-model="order.poll">
            <v-radio label="No enviada" :value="0"></v-radio>
            <v-radio label="Enviada" :value="1"></v-radio>
            <v-radio label="Completada" :value="2"></v-radio>
          </v-radio-group>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" flat @click.native="updatePoll"> Ingresar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog.orderClose" scrollable max-width="500px">
      <v-card>
        <TitleDialog :title="`Cerrar Pedido`"> </TitleDialog>
        <v-card-text>
          <v-container class="pa-0" grid-list-lg>
            <v-layout wrap>
              <v-flex xs12>
                <v-img
                  v-if="!cierre.info.emptyPhoto && closePhoto !== ''"
                  class="mb-2"
                  :src="closePhoto"
                  style="width: 200px; height: 200px"
                />
                <FileReader
                  v-if="!cierre.info.emptyPhoto && closePhoto === ''"
                  :name="'Foto de entrega'"
                  :flat="false"
                  :get-file="this.getFile"
                  @load="closePhoto = $event"
                ></FileReader>
                <v-btn v-else-if="!cierre.info.emptyPhoto" color="error" block @click.native="closePhoto = ''">
                  Quitar Foto
                  <v-icon right dark>cloud_upload</v-icon>
                </v-btn>
                <v-switch v-model="cierre.info.emptyPhoto" class="ma-0" hide-details label="Sin foto"></v-switch>
              </v-flex>
              <v-flex xs12>
                <v-menu
                  v-model="closeDateDialog"
                  :close-on-content-click="false"
                  lazy
                  transition="scale-transition"
                  offset-y
                  full-width
                  min-width="290px"
                >
                  <template slot="activator">
                    <v-text-field
                      v-model="cierre.date"
                      label="Fecha de entrega"
                      prepend-icon="event"
                      readonly
                      hide-details
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="cierre.date" @input="closeDateDialog = false"></v-date-picker>
                </v-menu>
              </v-flex>
              <v-flex xs12>
                <v-menu
                  v-model="closeTimeDialog"
                  :close-on-content-click="false"
                  lazy
                  transition="scale-transition"
                  offset-y
                  full-width
                  min-width="290px"
                >
                  <template slot="activator">
                    <v-text-field
                      v-model="cierre.time"
                      label="Hora de entrega"
                      prepend-icon="event"
                      readonly
                      hide-details
                    ></v-text-field>
                  </template>
                  <v-time-picker v-model="cierre.time" @click:minute="closeTimeDialog = false"></v-time-picker>
                </v-menu>
              </v-flex>
              <v-flex xs12 sm12 lg12>
                <v-select
                  v-model="cierre.info.type"
                  hide-details
                  :items="cierres"
                  item-text="value"
                  item-value="id"
                  label="Tipos de cierre"
                  box
                ></v-select>
                <v-divider class="my-3"></v-divider>
                <v-layout v-if="cierre.info.type === 'rempresa'" wrap>
                  <v-flex xs12>
                    <v-select
                      v-model="cierre.info.en"
                      hide-details
                      :items="[
                        {
                          id: 'en la recepción de la empresa por',
                          value: 'Recepción'
                        },
                        {
                          id: 'por el compañero de trabajo Sr.',
                          value: 'Compañero'
                        },
                        {
                          id: 'por la compañera de trabajo',
                          value: 'Compañera'
                        },
                        {
                          id: 'por el guardia de la empresa',
                          value: 'Guardia'
                        },
                        { id: 'por la secretaria', value: 'Secretaria' }
                      ]"
                      item-text="value"
                      item-value="id"
                      label="Recibido por"
                      box
                    ></v-select>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field v-model="cierre.info.persona" label="Nombre" box hide-details></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-select
                      v-model="cierre.info.porqueno"
                      hide-details
                      :items="[
                        {
                          id:
                            'La destinataria(o) no pudo recibir las flores personalmente ya que se encontraba en una reunion al momento de la entrega',
                          name: 'En reunión'
                        },
                        {
                          id:
                            'La destinataria(o) no pudo recibir las flores personalmente ya que se encontraba en colación  al momento de la entrega',
                          name: 'En colación'
                        },
                        {
                          id:
                            'La destinataria(o) no pudo recibir las flores personalmente, ya que no se encontraba en la oficina al momento de la entrega, sin embargo, nos indican que va a regresar en el trascurso del día',
                          name: 'Salio pero regresa'
                        },
                        {
                          id:
                            'La destinataria(o) no pudo recibir las flores, ya que, se encontraba ocupada al momento de la entrega para recibirlas personalmente',
                          name: 'Dest ocupada'
                        }
                      ]"
                      item-text="name"
                      item-value="id"
                      label="Datos adicionales"
                      box
                    ></v-select>
                  </v-flex>
                </v-layout>
                <v-layout v-else-if="cierre.info.type === 'redificio'" wrap>
                  <v-flex xs12>
                    <v-text-field v-model="cierre.info.persona" label="Nombre" box hide-details></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field v-model="cierre.info.telefono" label="Teléfono" box hide-details></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout v-else-if="cierre.info.type === 'pservicio'" wrap>
                  <v-flex xs12>
                    <v-text-field v-model="cierre.info.persona" label="Nombre" box hide-details></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout v-else-if="cierre.info.type === 'clinica'" wrap>
                  <v-flex xs12>
                    <v-text-field label="Nombre" box hide-details></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-select
                      v-model="cierre.info.clinica"
                      hide-details
                      :items="[
                        {
                          id:
                            'en la central de correspondencia de la Clinica Las Condes (en esta clinica no se permite la entrega directa en las habitaciones)',
                          name: 'Las Condes'
                        },
                        {
                          id:
                            'en la recepcion de la Clinica Alemana (en esta clinica no se permite la entrega directa en las habitaciones)',
                          name: 'Alemana'
                        },
                        {
                          id:
                            'en la recepcion de la Clinica Indisa (en esta clinica no se permite la entrega directa en las habitaciones)',
                          name: 'Indisa'
                        },
                        {
                          id:
                            'en la recepcion de la Clinica Juan Pablo II (en esta clinica no se permite la entrega directa en las habitaciones)',
                          name: 'Juan Pablo II'
                        },
                        {
                          id:
                            'en la recepcion de la Clinica UC San Carlos de Apoquindo (en esta clinica no se permite la entrega directa en las habitaciones)',
                          name: 'UC San Carlos'
                        },
                        {
                          id:
                            'en la central de estafeta de la Clinica Santa María (en esta clinica no se permite la entrega directa en las habitaciones)',
                          name: 'Santa María'
                        },
                        {
                          id:
                            'en la recepcion de la Clinica Tabancura (en esta clinica no se permite la entrega directa en las habitaciones)',
                          name: 'Tabancura'
                        },
                        {
                          id:
                            'en la recepcion de la Clinica Sara Moncada (en esta clinica no se permite la entrega directa en las habitaciones)',
                          name: 'Sara Moncada'
                        },
                        {
                          id:
                            'en la recepcion de la Clinica Las Nieves (en esta clinica no se permite la entrega directa en las habitaciones)',
                          name: 'Las Nieves'
                        },
                        {
                          id:
                            'en la recepcion de la Clinica Universidad Los Andes (en esta clinica no se permite la entrega directa en las habitaciones)',
                          name: 'Universidad Los Andes'
                        },
                        {
                          id: 'en la recepcion de la Clinica Dávila',
                          name: 'Clinica Dávila'
                        },
                        {
                          id: 'en la recepcion de la Clinica Cordillera',
                          name: 'Clinica Cordillera'
                        },
                        {
                          id: 'en la recepcion de la Clinica Las lilas',
                          name: 'Clinica Las lilas'
                        }
                      ]"
                      item-text="name"
                      item-value="id"
                      label="Clinica"
                      box
                    ></v-select>
                  </v-flex>
                </v-layout>
                <v-layout v-else-if="cierre.info.type === 'otra_persona'" wrap>
                  <v-flex xs12>
                    <v-text-field label="Nombre" box hide-details></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-select
                      v-model="cierre.info.familiar2"
                      hide-details
                      :items="[
                        {
                          id: 'la mama de la destinataria(o), Sra. ',
                          name: 'Mamá'
                        },
                        {
                          id: 'el papa de la destinataria(o), Sr. ',
                          name: 'Papá'
                        },
                        {
                          id: 'el esposo de la destinataria, Sr. ',
                          name: 'Esposo'
                        },
                        { id: 'el hijo de la destinataria(o), ', name: 'Hijo' },
                        { id: 'la hija de la destinataria(o), ', name: 'Hija' },
                        {
                          id: 'la hermana de la destinataria(o), ',
                          name: 'Hermana'
                        },
                        {
                          id: 'el hermano de la destinataria(o), ',
                          name: 'Hermano'
                        },
                        {
                          id: 'la tia de la destinataria(o), Sra. ',
                          name: 'Tia'
                        },
                        {
                          id: 'la tio de la destinataria(o), Sr. ',
                          name: 'Tio'
                        },
                        {
                          id: 'el primo de la destinataria(o), ',
                          name: 'Primo'
                        },
                        {
                          id: 'la prima de la destinataria(o), ',
                          name: 'Prima'
                        },
                        {
                          id: 'la abuela de la destinataria(o), Sra. ',
                          name: 'Abuela'
                        },
                        {
                          id: 'el abuelo de la destinataria(o), Sr. ',
                          name: 'Abuelo'
                        },
                        {
                          id: 'el suegro de la destinataria(o), Sr. ',
                          name: 'Suegro'
                        },
                        {
                          id: 'la suegra de la destinataria(o), Sra. ',
                          name: 'Suegra'
                        },
                        { id: '', name: 'Sin especificar' }
                      ]"
                      item-text="name"
                      item-value="id"
                      label="Familiar"
                      box
                    ></v-select>
                  </v-flex>
                </v-layout>
                <v-layout v-else-if="cierre.info.type === 'velatorio'" wrap>
                  <v-flex xs12>
                    <v-text-field
                      v-model="cierre.info.persona"
                      label="Recibido en velatorio por"
                      box
                      hide-details
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout v-else-if="cierre.info.type === 'vecino'" wrap>
                  <v-flex xs12>
                    <v-select
                      v-model="cierre.info.sexo_vecino"
                      hide-details
                      :items="[{ id: 'la vecina', name: 'Vecina' }, { id: 'el vecino', name: 'Vecino' }]"
                      item-text="name"
                      item-value="id"
                      label="Vecino o vecina?"
                      box
                    ></v-select>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field v-model="cierre.info.persona" label="Nombre" box hide-details></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field v-model="cierre.info.direccion" label="Direccion" box hide-details></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field v-model="cierre.info.telefono" label="Teléfono" box hide-details></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout v-else-if="cierre.info.type === 'especial'" wrap>
                  <v-flex xs12>
                    <v-text-field v-model="cierre.info.persona" label="Nombre" box hide-details></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field
                      v-model="cierre.info.relacion"
                      label="Establecer relacion"
                      box
                      hide-details
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field v-model="cierre.info.telefono" label="Teléfono" box hide-details></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout v-else-if="cierre.info.type === 'institucion'" wrap>
                  <v-flex xs12>
                    <v-text-field
                      v-model="cierre.info.nombre_institucion"
                      label="Nombre de la institución"
                      box
                      hide-details
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field v-model="cierre.info.nombre" label="Nombre" box hide-details></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field v-model="cierre.info.cargo" label="Cargo" box hide-details></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-select
                      v-model="cierre.info.porqueno"
                      hide-details
                      :items="[
                        { id: 'sindatos', name: 'Sin datos' },
                        {
                          id:
                            'La destinataria(o) no pudo recibir las flores personalmente ya que se encontraba en una reunion al momento de la entrega',
                          name: 'En reunión'
                        },
                        {
                          id:
                            'La destinataria(o) no pudo recibir las flores personalmente ya que se encontraba en colación  al momento de la entrega',
                          name: 'En colación'
                        },
                        {
                          id:
                            'La destinataria(o) no pudo recibir las flores personalmente, ya que no se encontraba al momento de la entrega, sin embargo, nos indican que va a regresar en el trascurso del día',
                          name: 'Salio pero regresa'
                        },
                        {
                          id:
                            'La destinataria(o) no pudo recibir las flores, ya que, se encontraba ocupada al momento de la entrega para recibirlas personalmente',
                          name: 'Destinataria ocupada'
                        }
                      ]"
                      item-text="name"
                      item-value="id"
                      label="Datos adicionales"
                      box
                    ></v-select>
                  </v-flex>
                </v-layout>
                <v-layout v-else-if="cierre.info.type === 'problemas_entrega'" wrap>
                  <v-flex xs12>
                    <v-select
                      v-model="cierre.info.tipo_problema_select"
                      hide-details
                      :items="[
                        {
                          id: 'no habían personas en la dirección de entrega',
                          name: 'Sin moradores'
                        },
                        {
                          id: 'la dirección de entrega indicada es no existe o está incorrecta',
                          name: 'Dirección incorrecta'
                        },
                        {
                          id: 'rechazaron la recepción del producto en la dirección de entrega',
                          name: 'Destinatario no acepta entrega'
                        },
                        {
                          id: 'en la dirección de entrega no reconocen a la persona destinataria',
                          name: 'No reconocen a la persona destinataria'
                        },
                        {
                          id: 'la persona destinataria no se encuentra registrada en la clinica',
                          name: 'Destinataria no se encuentra en la clinica'
                        }
                      ]"
                      item-text="name"
                      item-value="id"
                      label="Seleccione el problema que tuvo: "
                      box
                    ></v-select>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field
                      v-model="cierre.info.tipo_problema_select"
                      label="Problema que tuvo:"
                      box
                      hide-details
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="greyback" flat @click.native="dialog.orderClose = false"> Volver </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="secondary" flat :loading="loading" :disabled="loading" @click.native="updateCloseOrder">
            Actualizar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog.orderSendPhoto" max-width="500px">
      <v-card>
        <v-toolbar class="elevation-0" color="secondary" dark>
          <v-toolbar-title class="font-weight-regular">Enviar pedido al email</v-toolbar-title>
        </v-toolbar>
        <v-container class="pa-3" grid-list-lg>
          <v-layout>
            <v-flex xs12> </v-flex>
          </v-layout>
        </v-container>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="greyback" flat @click.native="dialog.orderSendPhoto = false"> Volver </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="secondary" flat :loading="loading" :disabled="loading" @click.native="updateSchedule">
            Actualizar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog.orderConfirmPayment" max-width="600px" persistent>
      <v-card>
        <TitleDialog :title="`Confirmar pago`"></TitleDialog>
        <v-card-text>
          <v-layout wrap>
            <v-flex xs12>
              <QuillEditorFormat
                v-model="confirmationMessageOrder"
                :reference="'orderComfirm'"
                placeholder="Mensaje de confirmacion"
              ></QuillEditorFormat>
            </v-flex>
            <v-flex xs12>
              <v-subheader>Notificar</v-subheader>
            </v-flex>
            <v-flex xs6>
              <v-switch v-model="confirmWSP" class="pa-0 ma-0" color="accent" label="WhatsApp" :value="1"></v-switch>
            </v-flex>
            <v-flex xs6>
              <v-switch v-model="confirmEmail" class="pa-0 ma-0" color="accent" label="Email" :value="1"></v-switch>
            </v-flex>
          </v-layout>
        </v-card-text>

        <v-divider light></v-divider>
        <v-card-actions>
          <v-btn color="greyback" flat @click.native="dialog.orderConfirmPayment = false"> Volver </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="secondary" flat :loading="loading" :disabled="loading" @click.native="confirmPayment">
            Actualizar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog.orderPublishPhotos" max-width="600px" persistent>
      <v-card>
        <TitleDialog :title="`Verificar publicacion de foto`"></TitleDialog>
        <v-card-text>
          <v-layout wrap>
            <v-flex xs12>
              producto
            </v-flex>
          </v-layout>
        </v-card-text>

        <v-divider light></v-divider>
        <v-card-actions>
          <v-btn color="greyback" flat @click.native="dialog.orderPublishPhotos = false"> Volver </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog.orderWhatsapp" max-width="600px" persistent>
      <v-card>
        <TitleDialog :title="`Enviar WhatsApp`"></TitleDialog>
        <v-card-text>
          <v-layout wrap>
            <v-flex xs12>
              <p v-if="order.user" class="body-2 mb-0">Hola {{ order.user.name }}</p>
              <p>De florería Rosalinda necesitamos comunicarnos con usted.</p>
              <v-textarea v-model="messageWsp" outline multi-line color="secondary" :label="`Mensaje`"> </v-textarea>
              <p>Por favor responda este mensaje para poder ayudarlo.</p>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-divider light></v-divider>
        <v-card-actions>
          <v-btn color="greyback" flat @click.native="dialog.orderWhatsapp = false"> Volver </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="secondary" flat :loading="loading" :disabled="loading" @click.native="sendWsp">
            Actualizar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-flex>
</template>

<script>
import Slick from 'vue-slick'
import { GET_API, ORDER, GET_ORDER_EMAIL } from '../../config'
import TitleDialog from '../useful/titleDialog.vue'
import FileReader from '../useful/FileReader.vue'
import QuillEditorFormat from '../useful/quillEditorFormat.vue'

export default {
  name: 'OrderNav',
  components: {
    Slick,
    TitleDialog,
    FileReader,
    QuillEditorFormat
  },
  props: ['order', 'getOrder', 'goRoute', 'dialogStatus'],
  data() {
    return {
      closeTimeDialog: false,
      closeDateDialog: false,
      closePhoto: '',
      Zindex: 1,
      offset: { top: 82, bottom: 0 },
      orderEmailConfirmation: '',
      cierre: {
        time: this.$moment().format('HH:MM'),
        date: this.$moment().format('YYYY-MM-DD'),
        info: {
          type: null
        },
        photo: '',
        emptyPhoto: false
      },
      cierres: [
        { id: 'personal', value: 'Personal' },
        { id: 'rempresa', value: 'En empresa' },
        { id: 'redificio', value: 'Por conserje' },
        { id: 'pservicio', value: 'Personal de servicio' },
        { id: 'clinica', value: 'Clínica' },
        { id: 'otra_persona', value: 'Por familiar' },
        { id: 'velatorio', value: 'En velatorio' },
        { id: 'comprador', value: 'Por comprador' },
        { id: 'vecino', value: 'Por vecina(o)' },
        { id: 'especial', value: 'Caso especial' },
        { id: 'institucion', value: 'En institución' },
        { id: 'problemas_entrega', value: 'Problemas de entrega' }
      ],
      editorOption: {
        placeholder: 'Texto acá',
        modules: {
          toolbar: '#toolbar'
        }
      },
      confirmWSP: false,
      confirmEmail: false,
      menuScheduleDate: false,
      orderMail: '',
      loading: false,
      loadingDialog: false,
      hiddenPhoto: false,
      btnHiddenPhoto: false,
      photoLarge: '',
      pollDetail: {},
      messageWsp: 'Tenemos un problema con tu pedido',
      carruselOption: {
        slidesToShow: 1,
        infinite: true,
        centerMode: false,
        dots: true,
        adaptiveHeight: true,
        autoplay: true,
        autoplaySpeed: 2000,
        swipeToSlide: true,
        arrows: false
      },
      status: {
        id: null,
        message: null,
        comments: null,
        notify: 0,
        important: 0,
        whatsapp: 0,
        photo: null,
        file: null
      },
      dialog: {
        schedule: false,
        status: false,
        photo: false,
        orderEmail: false,
        orderPoll: false,
        orderClose: false,
        orderSendPhoto: false,
        orderConfirmPayment: false,
        orderPublishPhotos: false,
        orderWhatsapp: false
      },
      schedule: {
        date: null,
        name: null
      },
      actions: [
        { text: 'Pedido completo', route: 'orderEmail' },
        { text: 'Encuesta', route: 'orderPoll' },
        { text: 'Cerrar Pedido', route: 'orderClose' },
        { text: 'Confirmar pago', route: 'orderConfirmPayment' },
        { text: 'Enviar Whatsapp', route: 'orderWhatsapp' }
      ],
      links: [
        { icon: 'link', text: 'Encuesta', route: 'copy' },
        { icon: 'link', text: 'Geolocalizacion', route: 'copy' },
        { icon: 'link', text: 'Estado del pedido', route: 'copy' }
      ],
      items: [
        { icon: 'person', text: 'Detalle', route: 'orderHome' },
        { icon: 'email', text: 'Mensaje', route: 'orderMessage' },
        { icon: 'local_florist', text: 'Productos', route: 'orderProducts' },
        { icon: 'history', text: 'Historial', route: 'orderHistory' },
        { icon: 'money', text: 'Pagos adicionales', route: 'orderExtraCost' }
        // { icon: 'local_florist', text: 'Insumos', route: 'orderInsumos' },
        // { icon: 'fab fa-whatsapp', text: 'Whatsapp', route: 'orderWhatsapp' },
      ],
      config: {
        font_names: this.fontReturn('font'),
        contentsCss: this.fontReturn('css'),
        toolbar: [
          {
            name: 'basicstyles',
            groups: ['basicstyles', 'cleanup'],
            items: ['Bold', 'Italic', 'Underline', '-', 'JustifyCenter']
          },
          { name: 'styles', items: ['Font', 'FontSize'] }
        ],
        language: 'es',
        height: 400,
        allowedContent: true
      },
      confirmationMessageOrder: ''
    }
  },
  computed: {
    shouldStick() {
      return this.$mq !== 'xs'
    },
    comments() {
      return this.$store.getters.getComments
    },
    states() {
      return this.$store.getters.getStates
    }
  },
  watch: {
    dialogStatus() {
      this.dialog.status = true
    },
    'status.comments': function status(e) {
      this.status.message = e.message
    },
    'dialog.orderEmail': function orderEmail(e) {
      if (e) {
        this.getOrderEmail()
      }
    },
    'dialog.orderPoll': function orderPoll(e) {
      if (e) {
        this.getOrderPoll()
      }
    }
  },
  mounted() {
    if (window.innerHeight < 740) {
      this.hiddenPhoto = true
    }
    this.updateform()
    if (this.order) {
      this.messageWsp = `Tenemos un problema con tu pedido ${this.order.id}`
    }
  },
  methods: {
    closeDialogStatus() {
      this.dialog.status = false
    },
    openPublishPhotos() {
      this.dialog.orderPublishPhotos = true
    },
    openWhatsapp() {
      this.dialog.orderWhatsapp = true
    },
    async updateStatus() {
      let error = false
      let message = ''
      if (this.status.message === '' || this.status.message === null) {
        error = true
        message = 'Debe ingresar un mensaje'
      }

      if (!error) {
        this.loading = true
        const formData = new FormData()
        formData.append('p', 'updateStatusOrder')
        formData.append('status', JSON.stringify(this.status))
        formData.append('statusNow', JSON.stringify(this.order.state))
        formData.append('photo', this.status.file)
        try {
          await this.$http.post(`${ORDER}/${this.order.id}/status`, formData)
          this.loading = false
          this.status.message = null
          this.status.comments = null
          this.status.file = null
          this.status.notify = 0
          this.status.important = 0
          this.status.whatsapp = 0
          this.getOrder()
          this.dialog.status = false
          this.$store.dispatch('changeSnack', {
            active: true,
            text: 'Actualizado'
          })
        } catch (e) {
          this.$store.dispatch('changeSnack', {
            active: true,
            text: 'Ocurrio un error, intente nuevamente'
          })
        }
      } else {
        this.$store.dispatch('changeSnack', { active: true, text: message })
      }
    },
    async updateSchedule() {
      try {
        this.loading = true
        await this.$http.post(`${ORDER}/${this.order.id}/schedule`, {
          schedule: this.schedule
        })
        this.getOrder()
        this.loading = false
        this.dialog.schedule = false
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Fecha/Horario actualizado'
        })
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    async getOrderEmail() {
      try {
        const res = await this.$http.get(`${ORDER}/${this.order.id}/email`)
        this.orderMail = res.data
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    async orderEmailSendAction() {
      try {
        this.loadingDialog = true
        this.$store.dispatch('setConfirm', { active: false })
        await this.$http.post(`${GET_ORDER_EMAIL}/${this.order.id}`, {
          orderMail: this.orderMail,
          email: this.orderEmailConfirmation
        })
        this.loadingDialog = false
        this.dialog.orderEmail = false
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Email enviado'
        })
      } catch (e) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    async getOrderPoll() {
      try {
        const res = await this.$http.get(`${ORDER}/${this.order.id}/poll`)
        this.pollDetail = res.data
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    async updatePoll() {
      try {
        await this.$http.post(`${ORDER}/${this.order.id}/poll`, {
          poll: this.order.poll
        })
        this.dialog.orderPoll = false
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Actualizado'
        })
        this.getOrder()
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    async confirmPayment() {
      try {
        await this.$http.post(`${ORDER}/${this.order.id}/payment`, {
          wsp: this.confirmWSP,
          email: this.confirmEmail,
          meessageOrder: this.confirmationMessageOrder
        })
        this.confirmWSP = false
        this.confirmEmail = false
        this.dialog.orderConfirmPayment = false
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Pago confirmado'
        })
        this.getOrder()
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    async changeOrderPriority(status) {
      const prioridad = status ? 'media' : 'baja'
      this.order.priority.name = prioridad
      try {
        this.$http.post(`${ORDER}/${this.order.id}/priority`, {
          status
        })
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    async updateCloseOrder() {
      try {
        const formData = new FormData()
        formData.append('file', this.cierre.photo)
        formData.append('id', this.order.id)
        formData.append('info', JSON.stringify(this.cierre.info))
        formData.append('time', this.cierre.time)
        formData.append('date', this.cierre.date)

        await this.$http.post(`${ORDER}/${this.order.id}/close`, formData)
        this.dialog.orderClose = false
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Subido con exito...'
        })
        this.getOrder()
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    async downloadItem(o) {
      const response = await this.$http.get(o.photo + '&download=1', {
        responseType: 'blob'
      })
      const blob = new Blob([response.data], { type: response.data.type })
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = o.id
      link.click()
      URL.revokeObjectURL(link.href)
    },
    async sendWsp() {
      try {
        await this.$http.post(`${ORDER}/${this.order.id}/wsp`, {
          message: this.messageWsp
        })
        this.dialog.orderWhatsapp = false
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Mensaje Enviado'
        })
        this.getOrder()
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    copyLink(url) {
      this.$copyText(url).then(e => {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Copiado con exito!'
        })
      })
    },
    goLink(url) {
      const win = window.open(url, '_blank')
      win.focus()
    },
    handleClickMenu(name) {
      this.updateform()
      this.dialog[name] = true
    },
    openImage(img) {
      this.photoLarge = img
      this.dialog.photo = true
    },
    fontReturn(type) {
      const myFonts = [
        'Open Sans',
        'Dancing Script',
        'Nothing You Could Do',
        'Great Vibes',
        'Marck Script',
        'Homemade Apple',
        'Reenie Beanie',
        'Just Me Again Down Here',
        'Caveat',
        'Dawning of a New Day',
        'Cedarville Cursive'
      ]
      let fontNames = 'Georgia;Times New Roman/Times New Roman, Times, serif'
      for (let i = 0; i < myFonts.length; i += 1) {
        fontNames = `${fontNames};${myFonts[i]}`
        myFonts[i] = `https://fonts.googleapis.com/css?family=${myFonts[i].replace(' ', '+')}`
      }
      if (type === 'css') {
        return ['/ckeditor/contents.css'].concat(myFonts)
      }
      return fontNames
    },
    updateform() {
      this.schedule.date = this.order.shipping.date
      this.schedule.name = this.order.shipping.schedule
      this.status.id = this.order.state.id
      const link = `<strong><a href="${this.order.links.estado}">${this.order.links.estado}</a></strong>`
      const wsp = `<a href="https://api.whatsapp.com/send/?phone=56988075501">WhatsApp</a>`
      this.confirmationMessageOrder = `Confirmamos recepción <strong>OK del pago</strong> de su pedido <strong>${
        this.order.id
      }</strong>, su pedido se encuentra en este momento en proceso con entrega programada para el día <strong>${this.$moment(
        this.order.shipping.date
      ).format('dddd')}, ${this.$moment(this.order.shipping.date).format('D')} de ${this.$moment(
        this.order.shipping.date
      ).format('MMMM')} de ${this.$moment(this.order.shipping.date).format('YYYY')} ${
        this.order.shipping.schedule
      } </strong><br><br>Tiene disponible el siguiente link ${link} para hacer seguimiento a su pedido, en este link podrá confirmar los datos de su pedido, revisar la ruta y ver la fotografía de entrega cuando su despacho esté listo. <br><br> Cualquier duda que tenga no dude en comunicarse con nosotros a nuestro canal de ${wsp}`
    },
    orderEmailSend() {
      this.$store.dispatch('setConfirm', {
        active: true,
        title: 'Enviar orden?',
        description: 'La orden se enviara al cliente',
        action: this.orderEmailSendAction
      })
    },
    getFile(file) {
      this.cierre.photo = file
    },
    handleFileUpload() {
      // eslint-disable-next-line prefer-destructuring
      this.status.file = this.$refs.file.files[0]
    }
  }
}
</script>

<style>
strong {
  font-weight: 600;
}
.img-entrega-download {
  position: relative;
}
</style>
